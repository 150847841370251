import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

//imports
import Home from "../../screens/Home";
import Careers from "../../screens/careers/Careers";
import Achievements from "../../screens/achievements/Achievements";
import Testimonials from "../../screens/testimonials/Testimonials";
import OurTeam from "../../screens/ourteam/OurTeam";
import Contact from "../../screens/contact/Contact";
import send from "../../../assets/images/whatsappform/send.svg";
import AboutPage from "../../screens/about/AboutPage";
import styled from "styled-components";
import Gallery from "../../screens/gallery/Gallery";
import BlogSingle from "../../screens/blogs/blog-single/BlogSingle";
import Blog from "../../screens/blogs/Blog";
import api from "../../general/api";
import { accountsConfig } from "../../../AxiosConfig";

function AppRouter() {
  const [isModal, setModal] = useState(false);

  const [number, setNumber] = useState("");

  const fetchWhatsAppNumber = () => {
    accountsConfig
      .get("company_profile/get-whatsapp-number-user/")
      .then((res) => {
        const StatusCode = res.data.app_data.StatusCode;
        const Data = res.data.app_data.data;

        if (StatusCode === 6000) {
          setNumber(Data);
        } else {
          setNumber([]);
        }
      });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    fetchWhatsAppNumber();
  }, []);

  const closeModal = () => {
    setModal(false);
  };
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              isModal={isModal}
              closeFunction={closeModal}
              number={number}
            />
          }
        />
        <Route path="/careers" element={<Careers />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about" element={<AboutPage />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogSingle />} />
      </Routes>
      {/* <CoverWhatsapp
        href={`https://api.whatsapp.com/send?phone=${number.phone}&text=Hi, I would like to know more.`}
      >
        <Text>Chat with us</Text>
        <Whatsapp>
          <i class="ri-whatsapp-line"></i>
        </Whatsapp>
        <Text>Whatsapp Us</Text>
      </CoverWhatsapp> */}
      <Chat>
        <TextDiv>Whatsapp for more details ...</TextDiv>
        <Send
          href={`https://api.whatsapp.com/send?phone=${number.phone}&text=Hi, I would like to know more.`}
        >
          <img src={send} alt="img" />
        </Send>
      </Chat>
    </>
  );
}

export default AppRouter;
const CoverWhatsapp = styled.a`
  position: fixed;
  z-index: 21000;
  bottom: 60px;
  text-decoration: none;
  align-items: center;
  padding: 0 0 0 10px;
  border-radius: 25px;
  right: 10px;
  color: #fff;
  background-color: #25d366;
  display: flex;
  @media all and (max-width: 480px) {
    bottom: 10px;
  }
`;
const Text = styled.h2`
  font-size: 14px;
  font-family: "mont-medium";
  padding-right: 20px;
  @media all and (max-width: 400px) {
    font-size: 12px;
  }
`;
const Whatsapp = styled.div`
  color: #ffffff;
  font-size: 36px;
  height: 50px;
  width: 50px;
  z-index: 10000;

  justify-content: center;
  align-items: center;
  text-decoration: none;
  /* position: fixed; */
  cursor: pointer;
  border-radius: 50%;
  /* bottom: 50px;
  right: 50px; */
  display: flex;
  span {
    font-size: 20px;
  }
  @media all and (max-width: 480px) {
    /* right: 30px; */
  }
  @media all and (max-width: 400px) {
    width: 40px;
    height: 40px;
    font-size: 26px;
  }
`;
const Chat = styled.div`
  display: flex;
  border: 1px solid #3b7cce;
  padding: 10px;
  position: fixed;
  z-index: 10000;
  bottom: 30px;
  right: 20px;
  border-radius: 20px;
  align-items: center;
  transition: 0.4s ease;

  @media all and (max-width: 480px) {
    display: none;
  }
`;
const TextDiv = styled.div`
  background-color: #3b7cce;
  padding: 15px;
  color: #fff;
  border-radius: 10px;
  margin-right: 10px;
  font-size: 14px;
  width: 90%;
  box-sizing: border-box;
  height: 40px;
  @media all and (max-width: 400px) {
    height: unset;
  }
`;
const Send = styled.a`
  width: 35px;
  cursor: pointer;
`;
